export const cardProgramRoot = "/card-program";



/***********************/
/** Card Program Paths */
export const cardProgramPaths = {
  // Kyc
  KYC: `${cardProgramRoot}/kyc`,
  KYC_DETAIL: `${cardProgramRoot}/kyc/:kycId`,
  kycDetail: (id: string | number) => `${cardProgramRoot}/kyc/${id}`,
  // Tribe
  TRIBE: `${cardProgramRoot}/tribe`,
  TRIBE_DETAIL: `${cardProgramRoot}/tribe/:tribeId`,
  tribeDetail: (id: string) => `${cardProgramRoot}/tribe/${id}`,
  TRIBE_TXNS: `${cardProgramRoot}/tribe/transactions`,
  TRIBE_TXN_DETAIL: `${cardProgramRoot}/tribe/transactions/:txnId`,
  tribeTxnDetail: (txnId: string) =>
    `${cardProgramRoot}/tribe/transactions/${txnId}`,
}

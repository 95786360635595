import { SelectFieldOption } from "../../../../components/forms/SelectField";


type FeeTxnType = "fee" | "parent" | "none";

interface FeeTypeOption extends SelectFieldOption {
  value: FeeTxnType | "";
}


export const FeeTypeOptions: FeeTypeOption[] = [
  {
    value: "",
    label: "All"
  },
  {
    value: "fee",
    label: "Fee (has a parent txn)"
  },
  {
    value: "parent",
    label: "Parent (has child fees)"
  },
  {
    value: "none",
    label: "None (txn with no fees)"
  }
]
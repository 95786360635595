import { Outlet, useOutletContext } from "react-router-dom";

import { CardProgramContext } from "..";



function Tribe() {
  const { user } = useOutletContext<CardProgramContext>();

  return (
    <Outlet context={{ user }} />
  );
}

export default Tribe;
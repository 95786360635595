import { useEffect, useState, MouseEvent, ChangeEvent } from "react";
import { toast } from "react-toastify";
import {
  Link, useLocation, matchPath, useOutletContext
} from "react-router-dom";

import inactive_icon from "../../../assets/vector-images/generic/inactive.svg";
import eye_hidden from "../../../assets/vector-images/generic/eye-off.svg";
import delete_icon from "../../../assets/vector-images/generic/delete.svg";
import active_icon from "../../../assets/vector-images/generic/active.svg";
import del_mdl_icon from "../../../assets/vector-images/modal/delete.svg";
import { useAppModal } from "../../../components/layout/AppModal/utils";
import eye_visible from "../../../assets/vector-images/generic/eye.svg";
import edit_icon from "../../../assets/vector-images/generic/edit.svg";
import view_icon from "../../../assets/vector-images/generic/view.svg";
import lock_icon from "../../../assets/vector-images/generic/lock.svg";
import { AuthRouteContext } from "../../../components/auth/AuthRoute";
import filter from "../../../assets/vector-images/generic/filter.svg";
import { TableColumn } from "../../../components/layout/Table/types";
import book from "../../../assets/vector-images/panel/book.svg";
import { missionPaths } from "../../../routes/missions/paths";
import { AllRegionOptions } from "../../../constants/core";
import { useQueryParams } from "../../../utils/components";
import { SelectField } from "../../../components/forms";
import useAppActions from "../../../redux/actions/app";
import useMissions from "../../../requests/missions";
import { useInput } from "../../../utils/forms";
import { Mission } from "../../../types/core";
import {
  Table, InfoPanel, Notification, PageHeader
} from "../../../components/layout";
import Styles from "./styles";



type Props = {
  isPublished?: boolean;
}

function MissionList({
  isPublished = true
}: Props) {
  const {
    updateFilters, locationSearch, queryParams
  } = useQueryParams();
  const search = queryParams.get("search") || "";
  const region = queryParams.get("region") || "";
  const { pathname } = useLocation();
  const { setAppModal, closeModal } = useAppModal();
  const { user } = useOutletContext<AuthRouteContext>();
  const { setAppLoading } = useAppActions();
  const {
    listMissions, deleteMission, updateMission, setSortNo
  } = useMissions();
  const [missions, setMissions] = useState<Mission[]>([]);
  const [treeGroups, setTreeGroups] = useState<Record<string, Mission[]>>({});
  const {
    value: regionValue,
    setValue: setRegion,
  } = useInput({
    initialValue: region ? region : "",
    selectValues: AllRegionOptions.map(option => option.value)
  });


  const updateRegion = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setRegion(value);
    updateFilters("region", value);
  }

  /******************************/
  /** List Mission with filters */
  const getMissionList = () => {
    const params = { isPublished, region, search };
    listMissions(params)
      .then((resData: Mission[]) => {
        setMissions(resData);
        const trees: string[] = Array.from(
          new Set(resData.map((item) => item.treeId.toString()))
        );
        const groups: Record<string, Mission[]> = {};
        trees.forEach((tree) => {
          groups[tree] = []
        });
        resData.forEach((item) => {
          const { treeId } = item;
          groups[treeId].push(item);
        });
        setTreeGroups(groups);
      }).catch(() => null);
  }

  /*****************************/
  /** Toggle Mission Published */
  const togglePublished = (e: MouseEvent, mission: Mission) => {
    e.preventDefault();
    if (matchPath(missionPaths.PUBLISHED, pathname)) {
      // Unpublish Mission
      setAppModal({
        title: "You're About to Unpublish this Mission",
        icon: eye_hidden,
        children: (
          `When this mission is unpublished, Users will no${" "
          }longer be able to see this in the app.`
        ),
        controls: (
          <div className="modal-controls">
            <button
              className="button white-black"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              className="button"
              onClick={() => {
                updateMission(
                  mission.id,
                  { isPublished: false }
                ).then(() => {
                  toast(
                    <Notification type="success">
                      Mission has been successfully unpublished!
                    </Notification>
                  );
                  closeModal();
                  window.location.reload();
                }).catch(() => null);
              }}
            >
              Okay
            </button>
          </div>
        )
      });
    } else {
      // Publish Mission
      setAppModal({
        title: "You're About to Publish this Mission",
        icon: eye_visible,
        children: (
          `When this mission is Published, Users will${" "
          }be able to see it in the app.`
        ),
        controls: (
          <div className="modal-controls">
            <button
              className="button white-black"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              className="button"
              onClick={() => {
                updateMission(
                  mission.id,
                  { isPublished: true }
                ).then(() => {
                  toast(
                    <Notification type="success">
                      Mission has been successfully published!
                    </Notification>
                  );
                  closeModal();
                  window.location.reload();
                }).catch(() => null)
              }}
            >
              Okay
            </button>
          </div>
        )
      });
    }
  }

  /**************************/
  /** Toggle Mission Active */
  const toggleActive = (e: MouseEvent, mission: Mission) => {
    e.preventDefault();
    if (mission.active) {
      // Deactivate Mission
      setAppModal({
        title: "You're About to Deactivate this Mission",
        icon: eye_hidden,
        children: (
          `When this mission is deactivated, Users will be able${" "
          }to view but not play the mission.`
        ),
        controls: (
          <div className="modal-controls">
            <button
              className="button white-black"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              className="button"
              onClick={() => {
                updateMission(
                  mission.id,
                  { active: false }
                ).then(() => {
                  toast(
                    <Notification type="success">
                      Mission has been successfully deactivated!
                    </Notification>
                  );
                  closeModal();
                  window.location.reload();
                }).catch(() => null)
              }}
            >
              Okay
            </button>
          </div>
        )
      });
    } else {
      // Activate Mission
      setAppModal({
        title: "You're About to Activate this Mission",
        icon: eye_visible,
        children: (
          `When this mission is activated, Users will${" "
          }be able to view and play the mission.`
        ),
        controls: (
          <div className="modal-controls">
            <button
              className="button white-black"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              className="button"
              onClick={() => {
                updateMission(
                  mission.id,
                  { active: true }
                ).then(() => {
                  toast(
                    <Notification type="success">
                      Mission has been successfully activated!
                    </Notification>
                  );
                  closeModal();
                  window.location.reload();
                }).catch(() => null);
              }}
            >
              Okay
            </button>
          </div>
        )
      });
    }
  }


  /*****************************/
  /** Confirm Mission Deletion */
  const confirmDelete = (e: MouseEvent, mission: Mission) => {
    e.preventDefault();
    setAppModal({
      title: "You're about to delete Mission",
      icon: del_mdl_icon,
      children: (
        `When this Mission is deleted, Users and Collaborators${" "
        }will not be able to see this.`
      ),
      controls: (
        <div className="modal-controls">
          <button
            className="button white-black"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            className="button error"
            onClick={() => {
              deleteMission(mission.id)
                .then(() => {
                  toast(
                    <Notification type="success">
                      Mission has been successfully deleted.
                    </Notification>
                  );
                  closeModal();
                  window.location.reload();
                }).catch(() => null);
            }}
          >
            Confirm
          </button>
        </div>
      )
    });
  }

  const columns: TableColumn[] = [
    {
      title: "No.",
      key: "sortNo"
    },
    {
      title: "Mission",
      key: "title",
      content: (mission: Mission) => (
        <Link
          to={missionPaths.edit(mission.id)}
          className="table-title"
        >
          <img src={mission.thumbnailUrl} className="thumbnail" alt="" />
          <div>{mission.title}</div>
          <div className={`region ${mission.tree.region}`}>
            {mission.tree.region}
          </div>
        </Link>
      )
    },
    {
      title: "Desert",
      key: "tree",
      content: (mission: Mission) => (
        mission.tree.desert.name
      )
    },
    {
      title: "Quizzes",
      key: "quizzes",
      content: (mission: Mission) => mission.quizzes.length
    },
    {
      title: "Tokens",
      key: "tokens"
    },
    {
      title: "Actions",
      key: "",
      className: "al-left",
      content: (mission: Mission) => (
        <div className="flex">
          <Link to={missionPaths.edit(mission.id)}>
            <img src={edit_icon} alt="" />
          </Link>
          <button onClick={(e) => togglePublished(e, mission)}>
            <img alt=""
              src={mission.isPublished ? lock_icon : view_icon}
            />
          </button>
          <button onClick={(e) => toggleActive(e, mission)}>
            <img alt=""
              src={mission.active ? active_icon : inactive_icon}
            />
          </button>
          {user.userType === "admin" ? (
            <button onClick={(e) => confirmDelete(e, mission)}>
              <img src={delete_icon} alt="" />
            </button>
          ) : null}
        </div>
      )
    }
  ]

  const onTableSort = async (data: Mission[]): Promise<void> => {
    // Set ideal indices
    setAppLoading(true);
    for (const [i, mission] of data.entries()) {
      const index = i + 1;
      await setSortNo(mission.id, index);
    }
    window.location.reload();
  }


  useEffect(() => {
    getMissionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationSearch])



  return (
    <Styles className="root-content">
      {/* Header */}
      <PageHeader
        showSearch={true}
      >
        <SelectField
          options={AllRegionOptions}
          placeholder="All Regions"
          icon={filter}
          value={regionValue}
          onChange={updateRegion}
        />
        <Link
          to={missionPaths.CREATE}
          className="button sm mid-radius">
          New Mission
        </Link>
      </PageHeader>

      {/* Main Content */}
      <div className="page-wrapper">
        {missions.length > 0 ? (
          Object.entries(treeGroups).map(([treeId, items]) =>
            <div className="tree-group" key={treeId}>
              <div className="tree-meta flex align-center">
                <div className="title">Tree</div>
                <div className="tree-details flex">
                  <div className="flex">
                    <span className={`desert generic`}>
                      {items[0].tree.desert.name}
                    </span>
                  </div>
                  +
                  <div className="flex">
                    <span className={`region ${items[0].tree.region}`}>
                      {items[0].tree.region}
                    </span>
                  </div>
                </div>
              </div>
              <Table
                key={treeId}
                columns={columns}
                results={items}
                draggable={true}
                dragCallback={onTableSort}
              />
            </div>
          )
        ) : (
          <InfoPanel
            icon={book}
            title="No Missions"
          >
            <div className="description">
              No missions have been found with those filters.
            </div>
            <Link
              to={missionPaths.CREATE}
              className="button auto-width"
            >
              Create Misson
            </Link>
          </InfoPanel>
        )}
      </div>
    </Styles>
  );
}
export default MissionList;
import {
  useNavigate, useLocation, Navigate, Outlet
} from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import {
  Chart as ChartJS, CategoryScale, LinearScale,
  Title, Tooltip, Legend, ArcElement, PointElement,
  LineElement, BarElement,
} from "chart.js";

import error_icon from "../../../assets/vector-images/modal/error.svg";
import { nonAuthPaths } from "../../../routes/nonAuth/paths";
import { useAppModal } from "../../layout/AppModal/utils";
import useAppActions from "../../../redux/actions/app";
import { User } from "../../../requests/users/types";
import { useAppState } from "../../../redux/state";
import { ResponseCodes } from "../../../types";
import NavHeader from "../NavHeader";
import RootPage from "../RootPage";
import SideBar from "../Sidebar";
import Styles from "./styles";



ChartJS.register(
  CategoryScale, LinearScale, PointElement,
  LineElement, ArcElement, BarElement,
  Title, Tooltip, Legend
);

export type AuthRouteContext = {
  user: User;
}

function AuthRoute() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { setAppModal } = useAppModal();
  const { logoutUser, setSidebarOpen } = useAppActions();
  const { user, token, sidebarOpen } = useAppState();


  /***************************************************************/
  /** Append these headers to authenticate all outgoing requests */
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Token ${token}`;
  }

  /***********************************/
  /* ALL AUTHENTICATED API RESPONSES */
  axios.interceptors.response.use(
    (response: any) => response,
    (error) => {
      if (error && error.response) {
        const { status } = error.response;
        if (status === ResponseCodes.Unauthorized) {
          logoutUser();
          navigate(
            nonAuthPaths.SIGN_IN,
            { state: { previousPath: pathname } }
          );
        } else if (status === ResponseCodes.Forbidden) {
          setAppModal({
            icon: error_icon,
            title: "There's an Issue",
            children: (
              "Unfortunately you are not authorized to view this page"
            )
          });
        }
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    const handleResize = () => {
      setSidebarOpen(
        (window.innerWidth >= 992) &&
        (pathname.startsWith("/manage"))
      );
    };
    window.addEventListener("resize", handleResize);
    // Run once to set initial state based on current window size
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [pathname]);



  return (
    <RootPage>
      {(token && user) ? (
        <Styles className="root-page">
          {/* Side Menu */}
          <SideBar
            open={sidebarOpen}
            user={user}
          />
          {/* Page Content */}
          <div id="Console">
            <NavHeader />
            <Outlet context={{ user }} />
          </div>
        </Styles>
      ) : (
        <Navigate
          to={nonAuthPaths.SIGN_IN}
          state={{ previousPath: pathname }}
          replace
        />
      )}
    </RootPage>
  );
}

export default AuthRoute;
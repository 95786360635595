import axios from "axios";

import { ErrorResponse, PaginatedResponse } from "../../types";
import { TribeExternalTxn } from "./types";



/*  #################################### */
/** ## TRIBE EXTERNAL TXN API REQUESTS # */
/*  #################################### */
const useTribeExternalTxns = () => {
  /******************************/
  /** List Tribe External Transactions */
  const listTribeExternalTxns = (params: Record<string, string>) => {
    return new Promise<PaginatedResponse<TribeExternalTxn>>(
      (resolve, reject) => {
        axios({
          url: `${process.env.REACT_APP_SONA_PAYMENTS_API_URL
            }/v2/tribe-external-txns`,
          params
        }).then((res) => {
          const resData = res.data as PaginatedResponse<TribeExternalTxn>;
          resolve(resData);
        }).catch((error) => {
          reject(error as ErrorResponse);
        });
      });
  }

  /*********************************/
  /** Get Tribe External Txn by ID */
  const getTribeExternalTxn = (id: string) => {
    return new Promise<TribeExternalTxn>(
      (resolve, reject) => {
        axios({
          url: `${process.env.REACT_APP_SONA_PAYMENTS_API_URL
            }/v2/tribe-external-txns/${id}`
        }).then((res) => {
          const resData = res.data as TribeExternalTxn;
          resolve(resData);
        }).catch((error) => {
          reject(error as ErrorResponse);
        });
      });
  }


  return {
    listTribeExternalTxns,
    getTribeExternalTxn
  }
}

export default useTribeExternalTxns;
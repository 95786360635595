import * as ReactDOMClient from "react-dom/client";
import { StrictMode } from "react";

import ErrorBoundary from "./_App/ErrorBoundary";
import reportWebVitals from "./reportWebVitals";
import { Page404 } from "./pages";
import App from "./_App";



const container = document.getElementById("SONARoot");
const body = document.body;
const root = ReactDOMClient.createRoot(
  container
    ? container
    : body
);

root.render(
  <StrictMode>
    <ErrorBoundary
      fallback={<Page404 resource="Resource" />}
    >
      <App />
    </ErrorBoundary>
  </StrictMode>
);

reportWebVitals();
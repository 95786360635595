import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import useTribeExternalTxns from "../../../../requests/tribeExternalTxns";
import { Loader, PageHeader } from "../../../../components/layout";
import ModelDetail, {
  ModelDetailData
} from "../../../../components/layout/ModelDetail";
import { URLParams } from "../../../../constants";
import { useTxnDetailUtils } from "./utils";



function TribeTxnDetail() {
  const { txnId } = useParams<URLParams>();
  const { getTribeExternalTxn } = useTribeExternalTxns();
  const [loading, setLoading] = useState<boolean>(true);
  const [txnData, setTxnData] = useState<ModelDetailData[]>([]);
  const [feeData, setFeeData] = useState<ModelDetailData[][]>([]);
  const { buildTxnData, buildFeeData } = useTxnDetailUtils();


  /******************/
  /** Retrieve Data */
  const retrieveData = async (id: string) => {
    setLoading(true);
    try {
      const txn = await getTribeExternalTxn(id);
      setTxnData(buildTxnData(txn));
      setFeeData(buildFeeData(txn))
    } catch { }
    finally {
      setLoading(false);
    }
  }


  useEffect(() => {
    if (!txnId) return;
    retrieveData(txnId);
  }, [txnId])



  return (
    <div className="root-content">
      {/* Header */}
      <PageHeader />

      {/* Main Content */}
      <div className="page-wrapper">
        {loading ? (
          <Loader />
        ) : (
          <div className="flex wrap">
            <section className="flex-fill">
              <h1 className="title jumbo">Transaction</h1>
              <ModelDetail
                title={null}
                data={txnData}
              />
            </section>
            {feeData.length ? (
              <section className="flex-fill">
                <h2 className="title jumbo">Fees</h2>
                <div className="flex wrap">
                  {feeData.map(fee =>
                    <ModelDetail
                      title={null}
                      data={fee}
                    />
                  )}
                </div>
              </section>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}

export default TribeTxnDetail;
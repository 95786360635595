import { FormEvent, ReactNode, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { useQueryParams } from "../../../utils/components";
import { useInput } from "../../../utils/forms";
import TextField from "../../forms/TextField";
import Styles from "./styles";



type NvLnk = {
  name: string;
  path: string;
  currentPath: boolean;
}
type Props = {
  className?: string;
  title?: ReactNode;
  showSearch?: boolean;
  children?: ReactNode;
}

function PageHeader({
  title,
  showSearch,
  className,
  children
}: Props) {
  const { pathname } = useLocation();
  const { queryParams, updateFilters } = useQueryParams();
  const search = queryParams.get("search") || "";
  const [crumbLinks, setCrumbLinks] = useState<NvLnk[]>([]);
  const [searchDelay, setSearchDelay] = useState<any>();
  const {
    value: searchValue,
    stringChange: searchValueCh
  } = useInput({
    initialValue: decodeURIComponent(search) ?? ""
  });

  const searchQuery = (e?: FormEvent) => {
    if (e) e.preventDefault();
    updateFilters("search", searchValue);
  }


  useEffect(() => {
    const paths = pathname.split("/").filter(path => path !== "");
    let currentPath = "";
    // Create a new array of breadcrumb links
    const newCrumbLinks = paths.map((path, index) => {
      currentPath = currentPath === "" ? path : `${currentPath}/${path}`;
      return {
        path: currentPath,
        name: path,
        currentPath: index === paths.length - 1
      };
    });
    setCrumbLinks(newCrumbLinks);
  }, [pathname])

  useEffect(() => {
    if (!search && !searchValue) return;
    clearTimeout(searchDelay);
    setSearchDelay(setTimeout(() => {
      searchQuery();
    }, 1000));
  }, [searchValue])



  return (
    <Styles className={
      `page-header flex column ${className
        ? className
        : ""}`}
    >
      <div className="primary-row flex justify-between align-center wrap">
        {/* Breadcrumbs */}
        <ul className="breadcrumbs">
          {crumbLinks.map((crumb, index) => !crumb.currentPath ? (
            <li key={index} className="crumb">
              <Link to={`/${crumb.path}`}>
                {crumb.name.replace(/-/g, " ")}
              </Link>
            </li>
          ) : (
            <li key={index} className="crumb current">
              {title ? title : crumb.name}
            </li>
          ))}
        </ul>

        {/* Search Field */}
        {showSearch ? (
          <form
            className="search-form"
            onSubmit={searchQuery}
            noValidate
          >
            <TextField
              value={searchValue}
              onChange={searchValueCh}
              placeholder="Search"
              className="search-field"
            />
          </form>
        ) : null}
      </div>
      {children ? (
        <div className="secondary-row flex justify-between">
          {children}
        </div>
      ) : null}
    </Styles>
  );
}

export default PageHeader;
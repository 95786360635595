export const accountsRoot = "/accounts";



export const accountPaths = {
  // Users
  USERS: `${accountsRoot}/users/`,
  USER_DETAIL: `${accountsRoot}/users/:userId`,
  userDetail: (id: string) => `${accountsRoot}/users/${id}`,
  // Families
  FAMILIES: `${accountsRoot}/families`,
  FAMILY_DETAIL: `${accountsRoot}/families/:familyId`,
  familyDetail: (id: string) => `${accountsRoot}/families/${id}`,
  // Clan
  CLANS: `${accountsRoot}/clans`,
  CLAN_DETAIL: `${accountsRoot}/clans/:clanId`,
  clanDetail: (id: string) => `${accountsRoot}/clans/${id}`
}
import { Link } from "react-router-dom";
import moment from "moment";

import { TribeExternalTxn } from "../../../../requests/tribeExternalTxns/types";
import { ModelDetailData } from "../../../../components/layout/ModelDetail";
import { cardProgramPaths } from "../../../../routes/cardProgram/paths";
import { dateAndTimeFormat } from "../../../../utils/dates";
import { decimalPrice } from "../../../../utils/numbers";
import {
  currencySymbolFromIson, optionalString
} from "../../../../utils/strings";



export const useTxnDetailUtils = () => {
  const buildTxnData = (txn: TribeExternalTxn) => {
    const {
      transaction_id, parent_transaction_id, description, transaction_amount,
      transaction_original_amount, transaction_currency_ison,
      transaction_local_time, transaction_fee, transaction_fee_amount,
      trans_link, transmission_date, transmission_time,
      billing_amount, billing_currency_ison, billing_conversion_rate,
      settlement_amount, settlement_currency_ison, settlement_conversion_rate,
      holder_amount, holder_currency_ison, holder_conversion_rate,
      holder_billing_amount, holder_billing_currency_ison,
      merchant, merchant_id, merchant_name,
      merchant_state, mcc,
      card_id, account_id, reconciliation_conversion_rate,
      currency_convertion_fee, cashback_amount, base_conversion_rate,
      suspicious, requestType, tribeId
    } = txn;
    const modelData: ModelDetailData[] = [
      // Transaction
      {
        name: "Transaction ID",
        description: transaction_id
      },
      {
        name: "Parent Transaction ID",
        description: parent_transaction_id ? (
          <Link
            to={cardProgramPaths.tribeTxnDetail(parent_transaction_id)}
            className="link underline"
          >
            {parent_transaction_id}
          </Link>
        ) : "-"
      },
      {
        name: "Description",
        description: optionalString(description)
      },
      {
        name: "Transaction Amount",
        description: (
          `${currencySymbolFromIson(transaction_currency_ison)
          }${decimalPrice(transaction_amount)}`
        )
      },
      {
        name: "Transaction Original Amount",
        description:
          transaction_original_amount ? (
            `${currencySymbolFromIson(transaction_currency_ison)
            }${decimalPrice(transaction_original_amount)}`
          ) : "-"
      },
      {
        name: "Transaction Local Time",
        description: transaction_local_time.toString()
      },
      {
        name: "Transaction Fee",
        description: (
          transaction_fee ? (
            `${currencySymbolFromIson(transaction_currency_ison)
            }${decimalPrice(transaction_fee)}`
          ) : "-"
        )
      },
      {
        name: "Transaction Fee Amount",
        description: (
          transaction_fee_amount ? (
            `${currencySymbolFromIson(transaction_currency_ison)
            }${decimalPrice(transaction_fee_amount)}`
          ) : "-"
        )
      },
      {
        name: "Trans Link",
        description: optionalString(trans_link)
      },
      {
        name: "Transmission Date",
        description: optionalString(transmission_date)
      },
      {
        name: "Transmission Time",
        description: optionalString(transmission_time)
      },

      // Billing
      {
        name: "Billing Amount",
        description: (
          billing_amount ? (
            `${currencySymbolFromIson(billing_currency_ison)
            }${decimalPrice(billing_amount)}`
          ) : "-"
        )
      },
      {
        name: "Billing Conversion Rate",
        description: optionalString(billing_conversion_rate)
      },

      // Settlement
      {
        name: "Settlement Amount",
        description: (
          settlement_amount ? (
            `${currencySymbolFromIson(settlement_currency_ison)
            }${decimalPrice(settlement_amount)}`
          ) : "-"
        )
      },
      {
        name: "Settlement Conversion Rate",
        description: optionalString(settlement_conversion_rate)
      },

      // Holder
      {
        name: "Holder Amount",
        description: (
          holder_amount ? (
            `${currencySymbolFromIson(holder_currency_ison)
            }${decimalPrice(holder_amount)}`
          ) : "-"
        )
      },
      {
        name: "Holder Conversion Rate",
        description: optionalString(holder_conversion_rate)
      },
      {
        name: "Holder Billing Amount",
        description: (
          holder_billing_amount ? (
            `${currencySymbolFromIson(holder_billing_currency_ison)
            }${decimalPrice(holder_billing_amount)}`
          ) : "-"
        )
      },

      // Merchant
      {
        name: "Merchant",
        description: optionalString(merchant)
      },
      {
        name: "Merchant ID",
        description: optionalString(merchant_id)
      },
      {
        name: "Merchant Name",
        description: optionalString(merchant_name)
      },
      {
        name: "Merchant State",
        description: optionalString(merchant_state)
      },
      {
        name: "Merchant MCC",
        description: optionalString(mcc)
      },

      // Miscellany
      {
        name: "Card ID",
        description: optionalString(card_id)
      },
      {
        name: "Account ID",
        description: optionalString(account_id)
      },
      {
        name: "Reconciliation Conversion Rate",
        description: optionalString(reconciliation_conversion_rate)
      },
      {
        name: "Currency Conversion Fee",
        description: optionalString(currency_convertion_fee)
      },
      {
        name: "Cashback Amount",
        description: optionalString(cashback_amount)
      },
      {
        name: "Base Conversion Rate",
        description: optionalString(base_conversion_rate)
      },
      {
        name: "Suspicious",
        description: optionalString(suspicious)
      },
      {
        name: "Request Type",
        description: requestType
      },
      {
        name: "Created At",
        description: (
          <div className="grey-text">
            {moment(
              `${transaction_local_time}`,
              "YYMMDDHHmmss"
            ).format(dateAndTimeFormat)}
          </div>
        )
      },
      {
        name: "Tribe ID",
        description: (
          <Link
            to={cardProgramPaths.tribeDetail(tribeId)}
            className="link underline"
          >
            {tribeId}
          </Link>
        )
      }
    ]

    return modelData;
  }

  const buildFeeData = (txn: TribeExternalTxn) => {
    if (!txn.fees) return [];
    let feesModelData = [];
    for (const fee of txn.fees) {
      const feeModel = buildTxnData(fee);
      feesModelData.push(feeModel);
    }

    return feesModelData;
  }


  return {
    buildTxnData,
    buildFeeData
  }
}
import { affirmationsRoot } from "./affirmations/paths";
import { cardProgramRoot } from "./cardProgram/paths";
import { collabRoot } from "./collaborators/paths";
import { dashboardRoot } from "./dashboard/paths";
import { accountsRoot } from "./accounts/paths";
import { missionRoot } from "./missions/paths";
import { billingRoot } from "./billing/paths";
import { nonAuthRoot } from "./nonAuth/paths";
import { profileRoot } from "./profile/paths";
import { offersRoot } from "./offers/paths";
import { assetsRoot } from "./assets/paths";
import { emailRoot } from "./emails/paths";
import { eventRoot } from "./events/paths";



export const rootPaths = {
  HOMEPAGE: "/",
  DASHBOARD: dashboardRoot,
  NON_AUTH: nonAuthRoot,
  ACCOUNTS: accountsRoot,
  ASSETS: assetsRoot,
  MISSIONS: missionRoot,
  AFFIRMATIONS: affirmationsRoot,
  BILLING: billingRoot,
  EMAILS: emailRoot,
  OFFERS: offersRoot,
  EVENTS: eventRoot,
  COLLABORATORS: collabRoot,
  PROFILE: profileRoot,
  CARD_PROGRAM: cardProgramRoot
}
import { Link } from "react-router-dom";
import moment from "moment";

import { cardProgramPaths } from "../../../../routes/cardProgram/paths";
import { TableColumn } from "../../../../components/layout/Table/types";
import { currencySymbol } from "../../../../utils/strings";
import { Tribe } from "../../../../requests/tribe/types";
import { decimalPrice } from "../../../../utils/numbers";



const useTribeListUtils = () => {
  const tableColumns: TableColumn[] = [
    {
      title: "User",
      key: "id",
      orderByKey: "user.firstName",
      content: (tribe: Tribe) => (
        <Link
          to={cardProgramPaths.tribeDetail(tribe.id)}
          className="table-title flex wrap"
        >
          <div className="text">
            {tribe.user.firstName} {tribe.user.lastName}
          </div>
          <div className="grey-text">
            ({tribe.user.username})
          </div>
        </Link>
      )
    },
    {
      title: "Reserve Acc. Balance",
      key: "reserveAccountBalance",
      orderByKey: "reserveAccountBalance",
      content: (tribe: Tribe) => (
        tribe.reserveAccountId ? (
          `${currencySymbol(tribe.currency)
          }${decimalPrice(tribe.reserveAccountBalance)}`
        ) : "-"
      )
    },
    {
      title: "Current Acc. Balance",
      key: "currentAccountBalance",
      orderByKey: "currentAccountBalance",
      content: (tribe: Tribe) => (
        tribe.currentAccountId ? (
          `${currencySymbol(tribe.currency)
          }${decimalPrice(tribe.currentAccountBalance)}`
        ) : "-"
      )
    },
    {
      title: "Card",
      key: "cardStatus",
      orderByKey: "cardStatus",
      content: (tribe: Tribe) => (
        tribe.cardId ? (
          <div className="flex column center wrap">
            <div className="grey-text">
              {tribe.cardNumber}
            </div>
            <div className={`status ${tribe.cardStatus}`}>
              {tribe.cardStatus}
            </div>
          </div>
        ) : ("-")

      )
    },
    {
      title: "Created At",
      key: "createdAt",
      orderByKey: "createdAt",
      content: (tribe: Tribe) => (
        moment(tribe.createdAt).format("Do MMM, YYYY")
      )
    },
    {
      title: "Last Active",
      key: "updatedAt",
      orderByKey: "updatedAt",
      content: (tribe: Tribe) => (
        moment(tribe.updatedAt).format("Do MMM, YYYY")
      )
    }
  ]


  return {
    tableColumns
  }
}

export default useTribeListUtils;
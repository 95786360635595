import { ChangeEvent, useEffect, useState } from "react";

import { TribeExternalTxn } from "../../../../requests/tribeExternalTxns/types";
import users_icon from "../../../../assets/vector-images/panel/users.svg";
import useTribeExternalTxns from "../../../../requests/tribeExternalTxns";
import filter from "../../../../assets/vector-images/generic/filter.svg";
import { PageHeader, Table } from "../../../../components/layout";
import { useQueryParams } from "../../../../utils/components";
import { SelectField } from "../../../../components/forms";
import { PaginatedResponse } from "../../../../types";
import { useInput } from "../../../../utils/forms";
import { FeeTypeOptions } from "./types";
import useTribeListUtils from "./utils";



function TribeTransactions() {
  const { locationSearch, queryParams, updateFilters } = useQueryParams();
  const pageSize = queryParams.get("pageSize") || "";
  const orderBy = queryParams.get("orderBy") || "";
  const search = queryParams.get("search") || "";
  const page = queryParams.get("page") || "";
  const feeTxnType = queryParams.get("feeTxnType") || "";
  const { listTribeExternalTxns } = useTribeExternalTxns();
  const { tableColumns } = useTribeListUtils()
  const [loading, setLoading] = useState<boolean>(true);
  const [tableData, setTableData] = useState<
    PaginatedResponse<TribeExternalTxn>
  >();
  const {
    value: feeValue,
    setValue: setFeeValue,
  } = useInput({
    initialValue: feeTxnType,
    selectValues: FeeTypeOptions.map(option => option.value)
  });


  /********************/
  /** Update Fee Type */
  const updateFeeType = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setFeeValue(value);
    updateFilters("feeTxnType", value);
  }


  /**************************/
  /** Retrieve Transactions */
  const retrieveData = async () => {
    setLoading(true);
    const params = {
      search,
      page,
      pageSize,
      orderBy,
      feeTxnType
    }
    try {
      const resData = await listTribeExternalTxns(params);
      setTableData(resData);
    } catch { }
    finally {
      setLoading(false);
    }
  }


  useEffect(() => {
    retrieveData();
  }, [locationSearch])



  return (
    <div className="root-content">
      {/* Header */}
      <PageHeader showSearch>
        <SelectField
          options={FeeTypeOptions}
          placeholder="All Fee Types"
          icon={filter}
          value={feeValue}
          onChange={updateFeeType}
        />
      </PageHeader>

      {/* Main Content */}
      <div className="page-wrapper">
        <Table
          {...tableData}
          loading={loading}
          columns={tableColumns}
          noData={{
            icon: users_icon,
            title: "No Transactions"
          }}
        />
      </div>
    </div>
  );
}

export default TribeTransactions;
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import user_icon from "../../../../assets/vector-images/generic/user-icon.svg";
import users_icon from "../../../../assets/vector-images/panel/users.svg";
import { cardProgramPaths } from "../../../../routes/cardProgram/paths";
import { PageHeader, Table } from "../../../../components/layout";
import { useQueryParams } from "../../../../utils/components";
import { KycWithUser } from "../../../../requests/kyc/types";
import { PaginatedResponse } from "../../../../types";
import useKyc from "../../../../requests/kyc";



function KycList() {
  const { locationSearch, queryParams } = useQueryParams();
  const pageSize = queryParams.get("pageSize") || "";
  const orderBy = queryParams.get("orderBy") || "";
  const search = queryParams.get("search") || "";
  const page = queryParams.get("page") || "";
  const { listKyc } = useKyc();
  const [loading, setLoading] = useState<boolean>(true);
  const [tableData, setTableData] = useState<
    PaginatedResponse<KycWithUser>
  >();

  const columns = [
    {
      title: "Name",
      key: "id",
      orderByKey: "user.firstName",
      content: (kyc: KycWithUser) => (
        <Link
          to={cardProgramPaths.kycDetail(kyc.id)}
          className="table-title"
        >
          <img
            alt=""
            src={(kyc.user.avatar?.url)
              ? kyc.user.avatar.url
              : user_icon
            }
          />
          <div>
            {kyc.user.firstName} {kyc.user.middleName} {kyc.user.lastName}
          </div>
        </Link>
      )
    },
    {
      title: "Status",
      key: "status",
      orderByKey: "status",
      content: (kyc: KycWithUser) => (
        <div className="text">
          {kyc.status.charAt(0).toUpperCase() +
            kyc.status.substring(1)}
        </div>
      )
    },
    {
      title: "Required Document",
      key: "requiredDocument",
      orderByKey: "requiredDocument",
      content: (kyc: KycWithUser) => (
        <div className="text">
          {kyc.requiredDocument.charAt(0).toUpperCase() +
            kyc.requiredDocument.substring(1)}
        </div>
      )
    },
    {
      title: "Created At",
      key: "createdAt",
      orderByKey: "createdAt",
      content: (kyc: KycWithUser) => (
        <div className="grey">
          {moment(kyc.createdAt).format("Do MMM YYYY, hh:mma")}
        </div>
      )
    },
    {
      title: "Updated At",
      key: "updatedAt",
      orderByKey: "updatedAt",
      content: (kyc: KycWithUser) => (
        <div className="grey">
          {moment(kyc.createdAt).format("Do MMM YYYY, hh:mma")}
        </div>
      )
    }
  ]

  /*****************/
  /** Get all data */
  const getKycData = async () => {
    const params = {
      search,
      page,
      pageSize,
      orderBy
    }
    try {
      const kyc = await listKyc(params)
      setTableData(kyc);
    } catch { }
    finally {
      setLoading(false)
    }
  }


  useEffect(() => {
    getKycData();
  }, [locationSearch])



  return (
    <div className="root-content">
      {/* Header */}
      <PageHeader
        showSearch={true}
        title="KYC"
      />

      {/* Main Content */}
      <div className="page-wrapper">
        <Table
          {...tableData}
          columns={columns}
          loading={loading}
          noData={{
            icon: users_icon,
            title: "No KYC Records"
          }}
        />
      </div>
    </div>
  );
}

export default KycList;
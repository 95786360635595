import { createGlobalStyle } from "styled-components";

import { properties, colors, functions, snippets } from ".";



const GlobalStyles = createGlobalStyle`
  ${''/* ################# */}  
  ${''/* # ROOT ELEMENTS # */}
  ${''/* ################# */}
  *, *::before, *::after {
    font-family: "Nunito", sans-serif;
    font-weight: 400;
    font-size: 16px;
    font-optical-sizing: auto;
    font-style: normal;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    color: ${colors.grey3};
  }
  html {
    height: 100%;
  }
  html, body {
    min-height: 100vh;
  }
  body {
    max-width: 100%;
    position: relative;
    overflow-x: hidden;
  }
  body, h1, h2, h3, h4, h5, h6, p, figure {
    margin: 0;
  }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
  }
  div, span, a {
    font-size: inherit;
    font-weight: inherit;
  }
  div {
    color: inherit;
  }
  ol, ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  figure {
    text-align: center;
    figcaption {
      text-align: left;
    }
  }
  img {
    object-position: center;
    object-fit: contain;
    vertical-align: middle;
    user-select: none;
  }
  a {
    outline: none;
    text-decoration: none;
    &:hover{
      text-decoration: none;
    }
  }
  ${''/* FORM ELEMENTS */}
  form {
    max-width: ${properties.maxFormWidth};
  }
  input, button, textarea, select {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    background-color: transparent;
  }
  input, textarea {
    font-size: 14px;
    &:focus, &:hover {
      outline: none;
    }
    &::placeholder {
      color: ${colors.grey2};
    }
  }
  button {
    cursor: pointer;
    user-select: none;
  }

  fieldset {
    border: none;
    padding: 0;
    margin: 0;
  }


  ${''/* ########### */}
  ${''/* # CLASSES # */}
  ${''/* ########### */}
  .root-page {
    min-height: 100vh;
    background-color: ${colors.grey5};
  }

  .root-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    overflow-y: auto;
    .page-wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      &>* {
        width: 100%;
      }
    }
  }

  .syncing {
    img {
      animation: sync 1s linear infinite;
    } 
    @keyframes sync {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }

  .grid {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    .full-row {
      grid-column: 1 / -1;
    }
  }

  .bold {
    font-weight: 700;
  }
  .semi-bold {
    font-weight: 600;
  }

  .title {
    &.jumbo {
      margin-bottom: 0.5rem;
      font-size: 2rem;
      font-weight: 600;
      color: ${colors.grey2};
    }
  }


  ${'' /* DISPLAY FLEX */}
  .flex {
    display: flex;
    gap: 7px;
    &.column {
      flex-direction: column;
    }
    &.row {
      flex-direction: row;
    }
    &.wrap {
      flex-wrap: wrap;
    }
    &.inline {
      display: inline-flex;
    }
    
    ${'' /* (Row) Main & Cross Axes */}
    &.flex-start {
      justify-content: flex-start;
      align-items: flex-start;  
    }
    &.center {
      justify-content: center;
      align-items: center;
    }
    &.flex-end {
      justify-content: flex-end;
      align-items: flex-end;
    }
    ${'' /* (Row) Main Axis */}
    &.justify-start {
      justify-content: flex-start;
    }
    &.justify-center {
      justify-content: center;
    }
    &.justify-end {
      justify-content: flex-end;
    }
    &.justify-between {
      justify-content: space-between;
    }
    ${'' /* (Row) Cross Axis */}
    &.align-start {
      align-items: flex-start;
    }
    &.align-center {
      align-items: center;
    }
    &.align-end {
      align-items: flex-end;
    }
    &.align-between {
      align-items: space-between;
    }
  }
  .flex-fill {
    flex: 1;
  }

  .gap-1 {
    gap: 1rem;
  }
  .gap-2 {
    gap: 2rem;
  }

  .card {
    padding: 24px 20px;
    background-color: ${colors.white};
    border: 1px solid ${colors.grey1};
    border-radius: 10px;
  }

  .grid-list {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
    .grid-item {
      display: block;
      color: ${colors.black};
      padding: 1rem;
      width: 100%;
      text-align: left;
      background-color: ${colors.white};
      border-left: 3px solid ${colors.primary};
    }
  }

  .stats-list {
    display: flex;
    gap: 0.5rem;
    font-size: 0.8rem;
    &>* {
      font-size: inherit;
      color: ${colors.grey6};
      &:not(:last-child) {
        position: relative;
        padding-right: 0.5rem;
        &::after {
          content: "";
          position: absolute;
          display: block;
          height: 2px; 
          width: 2px;
          right: -1px;
          top: 50%;
          transform: translateY(-50%);
          background-color: ${colors.grey6};
        }
      }
    }
  }

  ${'' /* MARGINS */}
  .mt-auto {
    margin-top: auto;
  }
  .ml-auto {
    margin-left: auto;
  }
  .mlr-auto {
    margin: 0 auto;
  }
  .mt-0-5 {
    margin-top: 0.5rem;
  }
  .mt-1 {
    margin-top: 1rem;
  }
  .mt-2 {
    margin-top: 1rem;
  }
  .mb-0-5 {
    margin-bottom: 0.5rem;
  }
  .mb-1 {
    margin-bottom: 1rem;
  }
  .mb-2 {
    margin-bottom: 2rem;
  }
  .ml-0-5 {
    margin-left: 0.5rem;
  }
  .ml-1 {
    margin-left: 1rem;
  }
  .ml-2 {
    margin-left: 2rem;
  }
  .mr-0-5 {
    margin-right: 0.5rem;
  }
  .mr-1 {
    margin-right: 1rem;
  }
  .mr-2 {
    margin-right: 2rem;
  }

  ${'' /* TEXT ALIGN */}
  .al-left {
    text-align: left !important;
  }
  .al-right {
    text-align: right !important;
  }
  .al-center {
    text-align: center !important;
  }

  .grey-text {
    color: ${colors.grey6};
  }

  .link {
    display: inline-block;
    max-width: fit-content;
    position: relative;
    color: ${colors.primary};
    padding-bottom: 1px;
    font-size: inherit;
    &.underline {
      &::after {
        transform: scaleX(1);
      }
    }
    &:hover, &:focus {
      color: ${colors.primary};
      &::after {
        transform: scaleX(1);
        transform-origin: center;
      }
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: ${colors.primary};
      height: 1px;
      width: 100%;
      transform: scaleX(0);
      transform-origin: center;
      transition: ${properties.transition};
    }
    &.black {
      color: ${colors.grey3};
      &::after {
        background-color: ${colors.grey3};
      }
    }
    &.blue {
      color: ${colors.accent4};
      &::after {
        background-color: ${colors.accent4};
      }
    }
    &.error {
      color: ${colors.error};
      &::after {
        background-color: ${colors.error};
      }
    }
  }

  .home-link-icon {
    display: block;
    img {
      width: 88px;
    }
    &.tall {
      img {
        width: 50px;
      }
    }
  }

  .btn-image {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 20px;
    }
  }
  
 
  ${''/* FORM CLASSES */}
  .field-column {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 2rem;
    min-width: 200px;
    &>* {
      width: initial;
    }
  }
  
  .field-row {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    align-items: center;
    &>* {
      flex: 1;
    }
  }
  
  .pseudo-field {
    padding: 16px 20px;
    font-size: 12px;
    background-color: ${colors.grey4};
    border: 1px solid ${colors.grey1};
    border-radius: 6px;
    color: ${colors.grey11};
    &.no-border {
      border: none;
      padding-left: 0;
      padding-right: 0;
    }
  }


  .button {
    user-select: none;
    width: 100%;
    max-width: ${properties.maxFieldWidth};
    color: ${colors.white};
    background-color: ${functions.colorLuminance(colors.primary, -0.1)};
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 7px;
    padding: 12px 30px;
    font-weight: 700;
    font-size: 0.8rem;
    overflow: hidden;
    transition: none;
    position: relative;
    z-index: 1;
    &::before {
      transition: none;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% - 5px);
      z-index: -1;
      background-color: ${colors.primary};
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
    }
    &>* {
      color: inherit;
      font-weight: inherit;
      font-size: inherit;
    }
    img {
      width: 16px;
    }
    &.sm {
      padding: 7px 16px;
      width: auto;
      font-size: 12px;
    }
    &.sm-radius {
      border-radius: 3px;
    }
    &.mid-radius {
      border-radius: 7px;
    }
    &.lg-radius {
      border-radius: 20px;
    }
    &.submit {
      margin-top: 2rem;
    }
    &.auto-width {
      width: fit-content;
    }
    &.white-black {
      color: ${colors.black};
      background-color: ${functions.colorLuminance(colors.white, -0.1)};
      &::before {
        background-color:  ${functions.colorLuminance(colors.white, -0.03)};
      }
    }
    &.white-primary {
      color: ${colors.primary};
      background-color: ${functions.colorLuminance(colors.white, -0.1)};
      &::before {
        background-color:  ${functions.colorLuminance(colors.white, -0.03)};
      }
    }
    &.black {
      color: ${colors.white};
      background-color: ${colors.black};
      border-color: ${colors.black};
    }
    &.transparent {
      color: ${colors.grey6};
      border: none;
      background-color: transparent;
      padding: 0;
      font-size: 12px;
      width: fit-content;
      &::before {
        display: none;
      }
      &:disabled {
        background-color: transparent;
        opacity: 0.5;
      }
      &:active, &:focus {
        transform: unset;
      }
    }
    &.full-width {
      width: 100%;
      max-width: unset;
    }
    &.error {
      color: ${colors.white};
      background-color: ${functions.colorLuminance(colors.error, -0.1)};
      &::before {
        background-color:  ${functions.colorLuminance(colors.error, -0.03)};
      }
    }
    &.sync {
      width: 85px;
      justify-content: left;
    }
    ${''/* Pseudo classes (Keep at the bottom for specificity) */}
    &:hover, &:focus {
      cursor: ponter;
    }
    &:active, &:focus {
      transform: translateY(2.5px);
      &::before {
        height: 100%;
      }
    }
    &:disabled {
      color: ${colors.grey11};
      background-color: ${colors.white};
      border: 2px solid ${colors.grey9};
      cursor: default;
      &::before {
        display: none;
      }
      &:active {
        transform: none;
        &::before {
          display: none;
        }
      }
    }
  }

  .validation-msg {
    font-size: 0.7rem;
    background-color: ${functions.rgba(colors.error, 0.1)};
    color: ${colors.error};
    padding: 7px 10px;
    border-radius: 5px;
  }


  ${'' /* Permissions, Statuses, Families, Clans */}
  .role,
  .status,
  .rank,
  .family,
  .clan,
  .region,
  .desert {
    padding: 5px;
    border-radius: 5px;
    font-size: 8px !important;
    font-weight: 600;
    text-transform: uppercase;
    display: inline-block;
    &.admin,
    &.success,
    &.successful,
    &.primary,
    &.ancient,
    &.activated,
    &.active,
    &.sent {
      background-color: ${functions.rgba(colors.primary, 0.1)};
      color: ${colors.primary};
    }
    &.generic-family,
    &.generic-clan,
    &.generic-desert,
    &.member,
    &.generic-region {
      background-color: ${functions.rgba(colors.secondary, 0.1)};
      color: ${colors.secondary};
    }
    &.medieval,
    &.collaborator,
    &.processing,
    &.sending,
    &.overdue,
    &.suspended,
    &.open,
    &.pending {
      background-color: ${functions.rgba(colors.accent6, 0.1)};
      color: ${colors.accent6};
    }
    &.colonial,
    &.blocked,
    &.expired,
    &.failed {
      background-color: ${functions.rgba(colors.accent7, 0.1)};
      color: ${colors.accent7};
    } 
    &.modern,
    &.notActivated,
    &.trial,
    &.scheduled {
      background-color: ${functions.rgba(colors.accent4, 0.1)};
      color: ${colors.accent4};
    }
    &.prehistory {
      background-color: ${functions.rgba(colors.accent9, 0.1)};
      color: ${colors.accent9};
    }
    &.generic,
    &.canceled,
    &.cancelled {
      background-color: ${functions.rgba(colors.black, 0.1)};
      color: ${colors.black};
    }
  }

  .avatar-placeholder {
    user-select: none;
    flex-shrink: 0;
    text-transform: uppercase;
    height: 48px;
    width: 48px;
    color: #fff;
    font-size: 1.2rem;
    border-radius: 50%;
    background-color: ${colors.accent8};
    ${snippets.flexCenter}
    font-weight: 600;
  }

  .form-width {
    max-width: ${properties.maxFormWidth};
    &.table-data {
      padding: 24px 20px;
      background-color: ${colors.white};
      border: 2px solid ${colors.grey7};
      border-radius: 10px;
      .table-row {
        font-size: 0.9rem;
        &:not(:last-child) {
          padding-bottom: 0.5rem;
          margin-bottom: 0.5rem;
          border-bottom: 1px solid ${colors.grey1};
        }
        .name {
          color: ${colors.grey6};
        }
        .description {
          font-weight: 700;
        }
      }
    }
  }


  .filters,
  .page-filters {
    margin-bottom: 30px !important;
    .select-field {
      width: fit-content;
      border: none;
      background-color: transparent;
      padding: 0;
      select {
        padding: 0;
        font-size: 0.8rem;
      }
    }
  }

  ${''/* ::::: THIRD-PARTY COMPONENTS ::::: */}
  .Toastify {
    .Toastify__toast-container {
      max-width: 50rem;
      margin-left: calc(${properties.sidebarWidth} / 2);
      width: auto;
      padding: 0;
    }
    .Toastify__toast {
      padding: 0;
      width: auto;
      background: transparent;
      animation-duration: 300ms !important;
      box-shadow: none;
      .Toastify__toast-body {
        background: transparent;
        padding: 0;
        margin: 0;
        width: fit-content;
      }
    }
  }
`;

export default GlobalStyles;
import { cardProgramPaths } from "./paths";
import { RouteType } from "../types";
import {
  KycList, KycDetail,
  TribeList, TribeDetail,
  TribeTxns, TribeTxnDetail
} from "../../pages/CardProgram/pages";



/***********************/
/* Card Program Routes */
export const cardProgramRoutes: RouteType[] = [
  // KYC
  {
    path: cardProgramPaths.KYC,
    element: <KycList />
  },
  {
    path: cardProgramPaths.KYC_DETAIL,
    element: <KycDetail />
  },
  // Tribe
  {
    path: cardProgramPaths.TRIBE,
    element: <TribeList />
  },
  {
    path: cardProgramPaths.TRIBE_DETAIL,
    element: <TribeDetail />
  },
  {
    path: cardProgramPaths.TRIBE_TXNS,
    element: <TribeTxns />
  },
  {
    path: cardProgramPaths.TRIBE_TXN_DETAIL,
    element: <TribeTxnDetail />
  }
]
import { FormEvent, useState, MouseEvent } from "react";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";

import { SelectFieldOption } from "../../../../components/forms/SelectField";
import usePlanPriceDiscounts from "../../../../requests/planPriceDiscounts";
import { useAppModal } from "../../../../components/layout/AppModal/utils";
import { accountPaths } from "../../../../routes/accounts/paths";
import { billingPaths } from "../../../../routes/billing/paths";
import { Notification } from "../../../../components/layout";
import {
  PlanPriceDiscount,
  PlanPriceDiscountCreateArgs
} from "../../../../requests/planPriceDiscounts/types";
import {
  FormField, FormValues
} from "../../../../components/forms/Form/types";
import { useBillingUtils } from "../../utils";



export const useDiscountCreateEditUtils = (
  planPriceDiscountId?: string
) => {
  const navigate = useNavigate();
  const { getPlanPriceOptions } = useBillingUtils();
  const { setAppModal, closeModal } = useAppModal();
  const {
    createPlanPriceDiscount, getPlanPriceDiscount,
    updatePlanPriceDiscount, deletePlanPriceDiscount
  } = usePlanPriceDiscounts();
  const [discount, setDiscount] = useState<PlanPriceDiscount>();
  const [formValues, setFormValues] = useState<FormValues>({});
  const [planPriceOptions, setPlanPriceOptions] = useState<
    SelectFieldOption[]
  >([]);


  /*******************************/
  /** Get the Plan Price Options */
  const loadPlanPrices = async () => {
    const planPrices = await getPlanPriceOptions();
    setPlanPriceOptions(planPrices);
  }

  /**************************************/
  /** Load current Discount for Editing */
  const getCurrentDiscount = async (planPriceDiscountId: string) => {
    try {
      const discount = await getPlanPriceDiscount(planPriceDiscountId);
      setDiscount(discount);
      const {
        description, planPriceId, percentage, daysDuration, code
      } = discount;
      setFormValues((prevState) => ({
        ...prevState,
        planPriceId: planPriceId.toString(),
        description,
        percentage,
        daysDuration,
        code
      }));
    } catch { }
  }

  /*****************/
  /** Save changes */
  const saveDetails = (e: FormEvent | MouseEvent) => {
    e.preventDefault();
    const data = formValues as PlanPriceDiscountCreateArgs;
    if (planPriceDiscountId) {
      updatePlanPriceDiscount(planPriceDiscountId, data)
        .then(() => {
          exitPage();
        }).catch(() => null);
    } else {
      createPlanPriceDiscount(data)
        .then(() => {
          exitPage();
        }).catch(() => null);
    }
  }

  /********************/
  /** Delete Discount */
  const deleteDiscount = (e: MouseEvent, id: string | number) => {
    e.preventDefault();
    deletePlanPriceDiscount(id)
      .then(() => {
        toast(
          <Notification type="success">
            Discount Successfully Deleted!
          </Notification>
        );
        navigate(billingPaths.LIST_DISCOUNTS);
      }).catch(() => null);
  }

  /***********************************/
  /** Confirmation Discount Deletion */
  const confirmDeletion = (e: MouseEvent) => {
    e.preventDefault();
    if (!planPriceDiscountId) return;
    setAppModal({
      title: "Delete Discount",
      children: "Are you sure you want to delete this Discount?",
      controls: (
        <div className="modal-controls">
          <button
            className="button white-primary"
            onClick={() => { closeModal() }}
          >
            No, Cancel
          </button>
          <button
            className="button error"
            type="button"
            onClick={(e) => {
              closeModal();
              deleteDiscount(e, planPriceDiscountId);
            }}
          >
            Yes, Delete
          </button>
        </div>
      ),
      cancelCallback: () => {
        closeModal();
      }
    });
  }

  /***************************************/
  /** Confirmation Modal & Page Redirect */
  const exitPage = () => {
    setAppModal({
      title: "Discount Saved",
      children: (
        `${planPriceDiscountId
          ? "Changes Saved!"
          : "Discount successfully added!"
        }`
      ),
      cancelCallback: () => {
        navigate(billingPaths.LIST_DISCOUNTS);
      }
    });
  }

  /****************/
  /** Form Fields */
  const formFields: FormField[] = [
    {
      type: "text",
      name: "description",
      label: "Description",
      groupClass: "field-group 1"
    },
    {
      type: "select",
      name: "planPriceId",
      label: "Plan",
      options: planPriceOptions,
      groupClass: "field-group 1"
    },
    {
      type: "number",
      name: "percentage",
      label: "Discount %",
      max: "100",
      placeholder: "Percentage of Discount",
      groupClass: "field-row 1"
    },
    {
      type: "number",
      name: "daysDuration",
      label: "Duration in Days",
      placeholder: "Duration of the discount in days",
      groupClass: "field-row 1"
    },
    {
      type: "text",
      name: "code",
      label: "Code",
      placeholder: "Discount Code",
      groupClass: "field-group 2"
    },
    ...((discount) ? [
      {
        type: "react-node",
        name: "singleUse",
        label: "",
        groupClass: "field-group 3",
        reactNode: (
          <div className="grey-text">
            Single Use: {discount.singleUse ? "Yes" : "No"}
          </div>
        )
      },
      {
        type: "react-node",
        name: "usedBy",
        label: "",
        groupClass: "field-group 3",
        reactNode: (
          <div className="grey-text">
            Used by: {discount.usedbyId
              ? <Link
                to={accountPaths.userDetail(discount.usedbyId)}
                className="link underline"
              >
                {discount.usedbyId}
              </Link>
              : "-"}
          </div>
        )
      },
    ] : [])
  ]


  return {
    planPriceOptions,
    formFields,
    formValues,

    getCurrentDiscount,
    confirmDeletion,
    loadPlanPrices,
    setFormValues,
    saveDetails,
  }
}
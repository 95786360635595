import { Link } from "react-router-dom";
import moment from "moment";

import { TribeExternalTxn } from "../../../../requests/tribeExternalTxns/types";
import { TableColumn } from "../../../../components/layout/Table/types";
import { cardProgramPaths } from "../../../../routes/cardProgram/paths";
import { currencySymbolFromIson } from "../../../../utils/strings";
import { dateAndTimeFormat } from "../../../../utils/dates";
import { decimalPrice } from "../../../../utils/numbers";



const useTribeTxnUtils = () => {
  const tableColumns: TableColumn[] = [
    {
      title: "Transction ID",
      key: "transaction_id",
      orderByKey: "transaction_id",
      content: (txn: TribeExternalTxn) => (
        <Link
          to={cardProgramPaths.tribeTxnDetail(txn.transaction_id)}
          className="link"
        >
          {txn.transaction_id}
        </Link>
      )
    },
    {
      title: "Amount",
      key: "transaction_amount",
      orderByKey: "transaction_amount",
      content: (txn: TribeExternalTxn) => (
        `${currencySymbolFromIson(
          txn.transaction_currency_ison ||
          txn.billing_currency_ison ||
          txn.holder_currency_ison
        )}${decimalPrice(
          txn.transaction_amount ||
          txn.billing_amount ||
          txn.holder_billing_amount
        )}`
      )
    },
    {
      title: "Merchant",
      key: "merchant",
      content: (txn: TribeExternalTxn) => (
        txn.merchant_name || txn.merchant || "-"
      )
    },
    {
      title: "Fee Type",
      key: "merchant_name",
      content: (txn: TribeExternalTxn) => (
        txn.fees?.length
          ? "Parent" : txn.parent_transaction_id
            ? "Fee"
            : "None"
      )
    },
    {
      title: "Created At",
      key: "transaction_local_time",
      orderByKey: "transaction_local_time",
      content: (txn: TribeExternalTxn) => (
        moment(
          `${txn.transaction_local_time}`,
          "YYMMDDHHmmss"
        ).format(dateAndTimeFormat)
      )
    }
  ]

  return {
    tableColumns
  }
}

export default useTribeTxnUtils;
import styled from "styled-components";



const Styles = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  /* overflow: hidden; */
  #Console {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    &>* {
      width: 100%;
    }
    &:nth-child(2) {
      flex: 1;
    }
    .root-content {
      padding-top: 0;
    }
  }
`;

export default Styles;
import { ModelDetailData } from "../../../../components/layout/ModelDetail";
import { currencySymbol, optionalString } from "../../../../utils/strings";
import { Tribe } from "../../../../requests/tribe/types";
import { decimalPrice } from "../../../../utils/numbers";
import useTribeTxnUtils from "../TxnList/utils";



const useTribeDetailUtils = () => {
  /************************/
  /** Holder Account Data */
  const buildHolderData = (tribe: Tribe) => {
    const { tribeHolder } = tribe;
    if (!tribeHolder) return null;
    const {
      id: holderId, first_name, last_name, address_line_1, phone_number,
      email, zip_code, city, date_of_birth, holder_limit_group_id
    } = tribeHolder;
    const modelData: ModelDetailData[] = [
      {
        name: "Holder ID",
        description: holderId
      },
      {
        name: "First Name",
        description: first_name
      },
      {
        name: "Last Name",
        description: last_name
      },
      {
        name: "Date of Birth",
        description: date_of_birth
      },
      {
        name: "Email",
        description: optionalString(email)
      },
      {
        name: "Address Line 1",
        description: address_line_1
      },
      {
        name: "Zip Code",
        description: zip_code
      },
      {
        name: "City",
        description: city
      },
      {
        name: "Phone Number",
        description: phone_number
      },
      {
        name: "Holder Limit ID",
        description: holder_limit_group_id
      }
    ]

    return modelData;
  }

  /*************************/
  /** Reserve Account Data */
  const buildReserveAccData = (tribe: Tribe) => {
    const { reserveAccountId, currency, reserveAccountBalance } = tribe;
    if (!reserveAccountId) return null;
    const modelData: ModelDetailData[] = [
      {
        name: "Reserve Account ID",
        description: reserveAccountId
      },
      {
        name: "Reserve Account Balance",
        description: (
          `${currencySymbol(currency)
          }${decimalPrice(reserveAccountBalance)}`
        )
      }
    ]

    return modelData;
  }

  /*************************/
  /** Current Account Data */
  const buildCurrentData = (tribe: Tribe) => {
    const { currentAccountId, currency, currentAccountBalance } = tribe;
    if (!currentAccountId) return null;
    const modelData: ModelDetailData[] = [
      {
        name: "Current Account ID",
        description: currentAccountId
      },
      {
        name: "Current Account Balance",
        description: (
          `${currencySymbol(currency)
          }${decimalPrice(currentAccountBalance)}`
        )
      }
    ]

    return modelData;
  }

  /**********************/
  /** Card Account Data */
  const buildCardData = (tribe: Tribe) => {
    const { cardId, cardNumber, cardStatus } = tribe;
    if (!cardId) return null;
    const modelData: ModelDetailData[] = [
      {
        name: "Card ID",
        description: cardId
      },
      {
        name: "Card Number",
        description: cardNumber
      },
      {
        name: "Card Status",
        description: (
          <div className={`status ${cardStatus}`}>
            {cardStatus}
          </div>
        )
      }
    ]

    return modelData;
  }

  const { tableColumns: tribeTxnColumns } = useTribeTxnUtils();

  return {
    buildHolderData,
    buildReserveAccData,
    buildCurrentData,
    buildCardData,
    tribeTxnColumns
  }
}

export default useTribeDetailUtils;
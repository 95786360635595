import { Outlet, useOutletContext } from "react-router-dom";

import { AuthRouteContext } from "../../components/auth/AuthRoute";



export type CardProgramContext = AuthRouteContext;

function CardProgram() {
  const { user } = useOutletContext<CardProgramContext>();

  return (
    <Outlet context={{ user }} />
  );
}

export default CardProgram;
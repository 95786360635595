import { useState, MouseEvent, useEffect } from "react";
import moment from "moment";

import { TableColumn } from "../../../components/layout/Table/types";
import bag from "../../../assets/vector-images/panel/bag.svg";
import { StatsCard } from "../../../components/layout/Card";
import useStatistics from "../../../requests/statistics";
import { currencySymbol } from "../../../utils/strings";
import { decimalPrice } from "../../../utils/numbers";
import useProducts from "../../../requests/products";
import useOrders from "../../../requests/orders";
import { Order } from "../../../types/offers";
import {
  PageHeader, Table, Card
} from "../../../components/layout";



const Metrics = () => {
  const { getBalance, getRevenue } = useStatistics();
  const { listProducts } = useProducts();
  const { listOrders } = useOrders();
  const [tableData, setTableData] = useState<any[]>([]);
  const [syncing, setSyncing] = useState<boolean>(false);
  const [stats, setStats] = useState<StatsCard[]>([]);


  const tableColumns: TableColumn[] = [
    {
      title: "Timestamp",
      key: "createdAt",
      content: (order: Order) => (
        <div className="grey-text">
          {moment(order.createdAt).format("Do MMM YYYY, hh:mma")}
        </div>
      )
    },
    {
      title: "Product",
      key: "product",
      className: "al-left",
      content: (order: Order) => (
        order.orderItems[0].productVariant.product.name
      )
    },
    {
      title: "Price",
      key: "price",
      content: (order: Order) => (
        `${currencySymbol(
          order.orderItems[0].productVariant.product.currency
        )}${decimalPrice(order.totalCost)}`
      )
    },
    {
      title: "Status",
      key: "status",
      className: "al-center",
      content: (order: Order) => (
        <div className={`status ${order.status}`}>
          {order.status}
        </div>
      )
    }
  ]


  /******************/
  /** Set card data */
  const upsertStat = (stat: StatsCard) => {
    setStats(prevStats => {
      const existingStatIndex = prevStats.findIndex(
        item => item.title === stat.title
      );
      if (existingStatIndex > -1) {
        // Update
        const updatedStats = [...prevStats];
        updatedStats[existingStatIndex] = stat;
        return updatedStats;
      } else {
        // Add
        return [...prevStats, stat];
      }
    });
  }


  /*****************/
  /** Get all data */
  const syncData = async (e?: MouseEvent) => {
    if (e) e.preventDefault();
    setSyncing(true);
    // Products & Balance
    await Promise.all([
      listProducts(),
      getBalance()
    ]).then((resData) => {
      const title = "Products & Float Balance";
      upsertStat({
        title,
        content: (
          <div className="flex align-end">
            <div className="value main">
              {resData[0].count}
            </div>
            <div className="value sub">
              {" / "}{currencySymbol(resData[1].currency)}{" "}
              {resData[1].value}
            </div>
          </div>
        )
      });
    });
    // Total Purchases and Table data
    await listOrders().then((resData) => {
      setTableData(resData.results);
      const totalOrders = resData.count;
      upsertStat({
        title: "Total Purchases",
        content: (
          <div className="value main">
            {totalOrders}
          </div>
        )
      });
    })
    // Revenue Generated
    await getRevenue().then((resData) => {
      upsertStat({
        title: "Revenue Generated",
        content: (
          <div className="flex align-end">
            <div className="value main">
              £ {decimalPrice(resData.gbpRevenue)}
            </div>
            <div className="value sub">
              {" / "}$ {decimalPrice(resData.usdRevenue)}
            </div>
          </div>
        )
      });
    })

    // Refunds
    upsertStat({
      title: "Refunds",
      content: (
        <div className="flex align-end">
          <div className="value main">
            £ 0.00
          </div>
          <div className="value sub">
            {" / "}$ 0.00
          </div>
        </div>
      )
    });
    setSyncing(false);
  }


  useEffect(() => {
    syncData();
  }, [])



  return (
    <div className="root-content">
      <PageHeader />

      <div className="page-wrapper flex column flex-fill">
        <div className="grid">
          {stats.map((stat, index) =>
            <Card key={index}>
              <div className="card-title">
                {stat.title}
              </div>
              {stat.content}
            </Card>
          )}
        </div>
        <Table
          className="mt-2"
          columns={tableColumns}
          results={tableData}
          noData={{
            icon: bag,
            title: "No Transactions Available",
          }}
        />
      </div>
    </div>
  );
}

export default Metrics;